const image = document.querySelector('img');
const wrapper = document.querySelector('#wrapper');
const button = document.querySelector('#detect');
const sum = document.querySelector('#sum');
const fileChange = document.querySelector('#fileChange');

if (typeof window.FaceDetector === "undefined") {
  alert("No face detection!");
  document.querySelector('body').innerHTML = "<h1>enable experimental features</h1>Enter in the URL-bar:<br><h2>chrome://flags/#enable-experimental-web-platform-features</h2>";
}

fileChange.onchange = (e) =>{
  var files = e.target.files;
  if (files.length === 1){
    sum.innerHTML = ""
    var reader = new FileReader();
    reader.onloadend = function(output){
      image.src = output.target.result;
    }
    const faces = document.querySelectorAll('.face')
    faces.forEach(face => {
      face.parentNode.removeChild(face);
    })
    
    reader.readAsDataURL(files[0])
  }
}


button.onclick = () => {
  sum.innerHTML = "start"
  var faceDetector = new FaceDetector();
  const faces = faceDetector.detect(image)
  
  faceDetector.detect(image).then(function (faces) {
    faces.forEach(function (face) {
      console.log(face);
      const { width, height, top, left } = face.boundingBox;
      console.log(width, height, top, left)
      const faceBox = document.createElement('div');
      faceBox.classList.add('face');
      faceBox.style.cssText = `
      width: ${width}px;
      height: ${height}px;
      top: ${top}px;
      left: ${left}px;
      `;
      wrapper.appendChild(faceBox);
      //return console.log(face);
    });
      sum.innerHTML = faces.length;
  }).catch(function (e) {
    console.error("Boo, Face Detection failed: " + e);
  });
};